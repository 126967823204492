<template>
	<el-form class="page" :model="doplata" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('doplata.model_doplate')" prop="id_modela_doplate">
					<select-doplate class="full" v-model="doplata.id_modela_doplate" ref="doplate"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('doplata.linija')" prop="id_linije">
					<select-vezane-linije class="full" v-model="doplata.id_linije" ref="linije"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6" v-if="doplata.id_modela_doplate === 1">
				<el-form-item :label="$t('doplata.fiksna_cena')" prop="fiksna_cena">
					<el-input v-model="doplata.fiksna_cena"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6" v-if="doplata.id_modela_doplate === 2">
				<el-form-item :label="$t('doplata.procenat')" prop="procenat">
					<el-input v-model="doplata.procenat"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6" v-if="doplata.id_modela_doplate === 3 || doplata.id_modela_doplate === 4">
				<el-form-item :label="$t('doplata.min_cena')" prop="min_cena">
					<el-input v-model="doplata.min_cena"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('doplata.min_cena_skupe')" prop="min_cena_skupe">
					<el-input v-model="doplata.min_cena_skupe"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('doplata.karta_datum_od')" class="width-full" prop="karta_datum_od">
					<el-date-picker
							:picker-options="pickerOptionsOd"
							v-model="doplata.karta_datum_od"
							:clearable="false"
							:editable="false"
							type="date"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('doplata.karta_datum_do')" class="width-full" prop="karta_datum_do">
					<el-date-picker
							:picker-options="pickerOptionsDo"
							v-model="doplata.karta_datum_do"
							:clearable="false"
							:editable="false"
							type="date"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('doplata.datum_od')" class="width-full" prop="datum_od">
					<el-date-picker
							:picker-options="pickerOptionsKartaOd"
							v-model="doplata.datum_od"
							:clearable="false"
							:editable="false"
							type="date"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('doplata.datum_do')" class="width-full" prop="datum_do">
					<el-date-picker
							:picker-options="pickerOptionsKartaDo"
							v-model="doplata.datum_do"
							:clearable="false"
							:editable="false"
							type="date"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'doplata-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			doplata: {
				id_modela_doplate: null,
				id_linije: null,
				fiksna_cena: 0,
				procenat: 0,
				min_cena: 0,
				min_cena_skupe: 0,
				datum_od: null,
				datum_do: null,
				karta_datum_od: null,
				karta_datum_do: null
			},
			rules: {
				id_modela_doplate: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_linije: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				datum_od: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				datum_do: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				karta_datum_od: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				karta_datum_do: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				fiksna_cena: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				procenat: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				min_cena: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				min_cena_skupe: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			},
			continentList: ['eu'],
			pickerOptionsDo: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			pickerOptionsOd: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			pickerOptionsKartaDo: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			pickerOptionsKartaOd: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('doplata.doplata')
		});

		this.$utils('stopLoadingAfter', [this.getDoplata()]);
	},
	methods: {
		getDoplata() {
			if (!this.formCreate) {
				return this.$get('ceneDoplate', { id: this.id }).then(data => {
					this.doplata = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.doplata);

				if (this.formCreate)
					this.$save('ceneDoplate', data);
				else
					this.$update('ceneDoplate', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	},
	components: {
		selectDoplate: require('../../components/select/modeliDoplate').default,
		selectVezaneLinije: require('../../components/select/vezaneLinije').default
	}
};
</script>
