<template>
	<el-select
			:value="value"
			v-loading="loading"
			@change="emit"
			clearable
			@clear="emit(null)"
			filterable
			:multiple="multiple"
			:disabled="disabled"
			:placeholder="$t('select.selectVezanaLinija')">
		<el-option
				v-for="linija in linije"
				:key="linija.id"
				:value="linija.id"
				:label="linija.naziv">
		</el-option>
	</el-select>
</template>

<script>
export default {
	name: 'select-linije',
	props: ['value', 'disabled', 'multiple'],
	data() {
		return {
			linije: [],
			loading: true
		};
	},
	mounted() {
		this.$get('vezaneLinije', { limit: 2000 }).then(data => {
			this.linije = [];
			data.content.forEach(linija => {
				if (linija.aktivno === 'DA')
					this.linije.push(linija);
			});
			this.$nextTick(() => {
				this.loading = false;
			});
		});
	},
	methods: {
		emit(id) {
			this.$emit('input', id);
			this.$emit('change', id);
		},
		get(id) {
			return this.linije.find(linije => linije.id === id);
		}
	}
};
</script>

