<template>
    <el-select :value="value"
               v-loading="loading"
               @change="emit"
               clearable
               @clear="emit(null)"
               filterable
               :placeholder="$t('select.selectModelDoplate')">
        <el-option v-for="val in modeliDoplate"
                   :key="val.id"
                   :value="val.id"
                   :label="val.naziv">
            <el-tooltip placement="top">
                <div slot="content" style="max-width: 500px">{{val.opis}}</div>
                <span>{{val.naziv}}</span>
            </el-tooltip>
        </el-option>
    </el-select>
</template>

<script>
export default {
	name: 'select-modeli-obracuna',
	props: ['value', 'change'],
	data() {
		return {
			modeliDoplate: [],
			loading: true
		};
	},
	mounted() {
		this.$get('modeliDoplate', { limit: 2000 }).then(data => {
			this.modeliDoplate = data;
			this.$nextTick(() => {
				this.loading = false;
			});
		});
	},
	methods: {
		emit(id) {
			this.$emit('input', id);
		},
		get(id) {
			return this.modeliDoplate.find(modeliDoplate => modeliDoplate.id === id);
		}
	}
};
</script>

